import React from 'react'
import { graphql } from 'gatsby'

import MaxWidthWrapper from '~components/MaxWidthWrapper'
import { NAVIGATION } from '~utils/constants'
import Layout from '~components/Layout'

import Post from './Post'
import messages from './messages'
import Container from './Content'

const metaTags = {
  title: 'Blog - Dein gesunder Lebensstil',
  description:
    'Hier findest du viele Infos und wertvolle Tipps über Fitness, Ernährung, Gesundheit, Sport und Lifestyle. Lass dich inspirieren.',
  slug: NAVIGATION.BLOG.BLOG,
  image: {
    url: 'PageBlog/HeroBlog_01--large',
    alt: 'Canyon Rennrad vor einer Graffiti-Wand',
  },
}

const hero = {
  pictureData: {
    path: 'PageBlog',
    alt: 'Canyon Rennrad vor einer Graffiti-Wand',
    fallback: {
      name: 'HeroBlog_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1OTU3IDMzNTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMyYzY4NzUiIGQ9Ik0wIDBoNTk1N3YzMzUwSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS42IDExLjYpIHNjYWxlKDIzLjI2OTUzKSIgZmlsbC1vcGFjaXR5PSIuNSI+PHBhdGggZmlsbD0iIzAxMDExNSIgZD0iTTE0OCAxMzlMMzAgMjZsMTc0IDUzeiIvPjxwYXRoIGZpbGw9IiMwMGQ4ZjEiIGQ9Ik0xNzEuOCAzMGwtNDEuMiAzMEw4NC4yLTRsNDEuMi0zMHoiLz48ZWxsaXBzZSBmaWxsPSIjYWY3ODYyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0xLjIwMjc1IDIyLjgxNjgzIC02NS44MTQ4MyAtMy40NjkzMiA0MyAxMDQuNCkiLz48ZWxsaXBzZSBmaWxsPSIjMDBjMGRlIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDIzLjggLTM4IDU4NC43KSBzY2FsZSgzOC45MzE5NiAyMy41ODAxNCkiLz48cGF0aCBmaWxsPSIjMGYwOTFhIiBkPSJNMTgyIDk0bDMxLTIwTDExMCAweiIvPjxlbGxpcHNlIGZpbGw9IiNiYTk5NmEiIGN4PSIyMTQiIGN5PSIxMTEiIHJ4PSIzNCIgcnk9IjE3Ii8+PGVsbGlwc2UgZmlsbD0iIzAwYjVlMCIgY3g9IjIzNiIgcng9IjU0IiByeT0iMjQiLz48cGF0aCBmaWxsPSIjMDhhZWMzIiBkPSJNMTUxIDczTC0xNS0xNmgxMzF6Ii8+PC9nPjwvc3ZnPg==',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroBlog_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMzA4IDM0NjIiPjxmaWx0ZXIgaWQ9ImMiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjU1IiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMyYTY0NzIiIGQ9Ik0wIDBoMjI5OHYzNDYySDB6Ii8+PGcgZmlsdGVyPSd1cmwoI2MpJyBmaWxsLW9wYWNpdHk9Jy41Jz48ZWxsaXBzZSBmaWxsPSIjMDBmY2ZmIiBmaWxsLW9wYWNpdHk9Ii41IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC00MDcuMzk3NSAyNzIuNzI4ODUgLTc0NC4yNjQ1MyAtMTExMS43NjkwOSAxOTM4LjUgMzUwLjQpIi8+PGVsbGlwc2UgZmlsbD0iIzAwMSIgZmlsbC1vcGFjaXR5PSIuNSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgzOS44IC0xNzIyLjIgMzI3Mi40KSBzY2FsZSgxNDM3LjM1NzQ2IDQ1Ny4yNjc2NikiLz48ZWxsaXBzZSBmaWxsPSIjYzY3YzVjIiBmaWxsLW9wYWNpdHk9Ii41IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDYzOC43NDg1NCAxNy45ODMxOSAtMTUuMDc2NzUgNTM1LjUxNDIgOTcxLjUgMjUwMi43KSIvPjxwYXRoIGZpbGwtb3BhY2l0eT0iLjUiIGQ9Ik0yMDA5IDUwNC41TDI1MDguNiAyNDN2ODAxbC01NTguMi01MTYuN3oiLz48cGF0aCBmaWxsPSIjMGJiMWM1IiBmaWxsLW9wYWNpdHk9Ii41IiBkPSJNMTE1LTE1NS41bDIxMjMuMSAxNDc0LTI0NDcuNy04Nzl6Ii8+PGVsbGlwc2UgZmlsbD0iIzAwOWZjNyIgZmlsbC1vcGFjaXR5PSIuNSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtODEuMDEzMTQgNDIwLjY5NTI4IC02NTcuMjcyMjggLTEyNi41NzA3IDYuOSAxNDE4LjMpIi8+PGVsbGlwc2UgZmlsbD0iIzAwMDAxNCIgZmlsbC1vcGFjaXR5PSIuNSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg1OTMuNjk3NzYgLTI5OS44OTgzMiA4NS4zMTIyNSAxNjguODg5NTUgODAwLjQgMTc2Ni42KSIvPjxwYXRoIGZpbGw9IiNmOWIwOTciIGZpbGwtb3BhY2l0eT0iLjUiIGQ9Ik04NzIuMyAxMTk2LjhMNDUzIDQ4MC4xIDIzNi43IDYyOC44eiIvPjwvZz48L3N2Zz4=',
      },
      breakpointMedium: {
        name: 'HeroBlog_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMDk0IDMwOTQiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMyNjU5NjciIGQ9Ik0wIDBoMzA5NHYzMDk0SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2IDYpIHNjYWxlKDEyLjA4NTk0KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzBiYmZkMiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxMjkuMTc2MTMgMTQuMzM4NiAtNS40ODE3NCA0OS4zODQ5IDEyOS4zIDExLjEpIi8+PGVsbGlwc2UgZmlsbD0iI2NjODc2NiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTcyIDQzLjUgODkpIHNjYWxlKDUzLjYzMjU1IDQzLjMwMTg2KSIvPjxlbGxpcHNlIGZpbGw9IiMwMDAwMTQiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMjQuOTMwODYgLTIzLjg0Mzg4IDg3LjUwNTI4IDkxLjQ5NDQzIDE0OCAxNDIuMSkiLz48cGF0aCBmaWxsPSIjMWIwMDAwIiBkPSJNMjI1LjMgNzcuMWwtNjMuNy01MS42IDE1LTE4LjYgNjMuOCA1MS42eiIvPjxwYXRoIGZpbGw9IiMwMGZhZmYiIGQ9Ik0xNTIgNTloNTN2MzBoLTUzeiIvPjxlbGxpcHNlIGZpbGw9IiMwMDAwMTMiIGN4PSIyNDYiIGN5PSIxNDgiIHJ4PSI0NiIgcnk9IjQ2Ii8+PGVsbGlwc2UgZmlsbD0iIzAwYThkMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgzNS42OTQ1OSAxNi45NTA1NyAtMTMuMDIxNDUgMjcuNDIwNjIgMTIuNiA5NC43KSIvPjxwYXRoIGZpbGw9IiNmZmNiYTYiIGQ9Ik0zMyAxNEwxNiAzNWwzMyAyMHoiLz48L2c+PC9zdmc+',
      },
      breakpointLarge: {
        name: 'HeroBlog_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1OTU3IDMzNTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiMyYzY4NzUiIGQ9Ik0wIDBoNTk1N3YzMzUwSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS42IDExLjYpIHNjYWxlKDIzLjI2OTUzKSIgZmlsbC1vcGFjaXR5PSIuNSI+PHBhdGggZmlsbD0iIzAxMDExNSIgZD0iTTE0OCAxMzlMMzAgMjZsMTc0IDUzeiIvPjxwYXRoIGZpbGw9IiMwMGQ4ZjEiIGQ9Ik0xNzEuOCAzMGwtNDEuMiAzMEw4NC4yLTRsNDEuMi0zMHoiLz48ZWxsaXBzZSBmaWxsPSIjYWY3ODYyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0xLjIwMjc1IDIyLjgxNjgzIC02NS44MTQ4MyAtMy40NjkzMiA0MyAxMDQuNCkiLz48ZWxsaXBzZSBmaWxsPSIjMDBjMGRlIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDIzLjggLTM4IDU4NC43KSBzY2FsZSgzOC45MzE5NiAyMy41ODAxNCkiLz48cGF0aCBmaWxsPSIjMGYwOTFhIiBkPSJNMTgyIDk0bDMxLTIwTDExMCAweiIvPjxlbGxpcHNlIGZpbGw9IiNiYTk5NmEiIGN4PSIyMTQiIGN5PSIxMTEiIHJ4PSIzNCIgcnk9IjE3Ii8+PGVsbGlwc2UgZmlsbD0iIzAwYjVlMCIgY3g9IjIzNiIgcng9IjU0IiByeT0iMjQiLz48cGF0aCBmaWxsPSIjMDhhZWMzIiBkPSJNMTUxIDczTC0xNS0xNmgxMzF6Ii8+PC9nPjwvc3ZnPg==',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const PageBlog = ({ data, pageContext, location }) => {
  const posts = data.allContentfulBlogPost.edges
  // const featuredPost = posts[0].node
  const isFirstPage = pageContext.currentPage === 1

  return (
    <Layout
      hero={isFirstPage ? hero : undefined}
      pictureData={isFirstPage ? hero.pictureData : undefined}
      metaTags={metaTags}
      pathname={location.pathname}
    >
      <MaxWidthWrapper>
        <Container isFirstPage={isFirstPage}>
          {posts &&
            posts.map(
              ({
                node: {
                  id,
                  title,
                  publishDateISO,
                  heroImage,
                  slug,
                  category,
                  description: { description },
                },
              }) => (
                <Post
                  key={id}
                  title={title}
                  publishDateISO={publishDateISO}
                  heroImage={heroImage}
                  slug={`${NAVIGATION.BLOG.BLOG}/${slug}`}
                  category={category}
                  description={description}
                />
              ),
            )}
        </Container>
      </MaxWidthWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $locale: String!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          category
          publishDate(formatString: "MMMM DD, YYYY")
          publishDateISO: publishDate(
            locale: $locale
            formatString: "DD. MMMM YYYY"
          )
          description {
            description
          }
          heroImage {
            altText
            imageLarge {
              url
              format
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...ImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageBlog
