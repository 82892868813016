import styled from 'styled-components'

const Divider = styled.div`
  height: 3px;
  width: ${({ theme }) => theme.spacing[64]};
  background: ${({ theme }) => theme.palette.primary.main};
  margin: ${({ theme }) => `0 auto ${theme.spacing[8]}`};
`

export default Divider
