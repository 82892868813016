import PropTypes from 'prop-types'

export const blogPropTypes = {
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publishDateISO: PropTypes.string.isRequired,
  heroImage: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    imageLarge: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.string.isRequired,
        image: PropTypes.shape({
          id: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number.isRequired,
              base64: PropTypes.string.isRequired,
              presentationHeight: PropTypes.number.isRequired,
              presentationWidth: PropTypes.number.isRequired,
              sizes: PropTypes.string.isRequired,
              src: PropTypes.string.isRequired,
              srcSet: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
}
