import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Typography from '~components/Typography'
import { toMultiline } from '~utils/helpers'
// import Img from '~components/Img'

import { blogPropTypes } from '../propTypes'

import Wrapper from './Wrapper'
import Header from './Header'
import Category from './Category'
import ReleaseDate from './ReleaseDate'
import Divider from './Divider'
import messages from './messages'

const Post = ({
  slug,
  heroImage,
  category,
  title,
  publishDateISO,
  description,
}) => (
  <Wrapper>
    <Link to={slug}>
      <Img
        alt={heroImage.altText}
        fluid={heroImage.imageLarge['0'].image.childImageSharp.fluid}
      />
    </Link>
    <div>
      <Category as="h3" type="header5" align="center" uppercase>
        {category}
      </Category>
      <Link to={slug}>
        <Header as="h2" type="header2" align="center" uppercase preWrap>
          {toMultiline(title)}
        </Header>
      </Link>
      <Divider />
      <ReleaseDate>
        {messages.releaseDatePrefix} {publishDateISO}
      </ReleaseDate>
      <Typography>{description}</Typography>
    </div>
  </Wrapper>
)

Post.propTypes = blogPropTypes

export default Post
