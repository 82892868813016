import styled from 'styled-components'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const ReleaseDate = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.ternary.main};
  ${fluidSpacingFunc([{ property: 'margin-bottom', values: [0.5] }])};
`

export default ReleaseDate
