import styled from 'styled-components'

import Typography from '~components/Typography'

const Header = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`

export default Header
