import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import MaxWidthWrapper from '~components/MaxWidthWrapper'

const Container = styled(MaxWidthWrapper)`
  ${media.mq1`
    padding-top: ${({ theme, isFirstPage }) =>
      isFirstPage ? 0 : theme.spacing[32]}};
  `};
  ${media.mq234`
    padding-top: ${({ theme, isFirstPage }) =>
      isFirstPage ? 0 : theme.spacing[64]}};
  `};
`

export default Container
